<template>
  <div>
    <div class="loginPage">
      <div class="loginWhiteBox">
        <div class="loginLogoBox">
          <img src="@/assets/images/yeet/yeet_logo.png" alt="">
        </div>
        <div class="loginTitlebox">
          <h4>Welcome to Yeetcommerce Dashboard!</h4>
          <p>To get connected with us, sign in with your personal information</p>
        </div>
        <div class="loginFormBox">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(loginHandle)">
              <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                <div class="formType">
                  <label for="email">Email<span>*</span></label>
                  <input 
                    type="text" 
                    name="email" 
                    id="email" 
                    v-model="form.email" 
                    class="form-control" 
                    placeholder="john@gmail.com"
                    autocomplete="email"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                <div class="formType">
                  <label for="password">Password<span>*</span></label>
                  <input 
                    :type="form.passwordFieldType ? 'password' : 'text'" 
                    v-model="form.password" 
                    name="password" 
                    id="password" 
                    class="form-control" 
                    placeholder="***********" 
                    autocomplete="on"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                  <button class="PasswordEyeBtn" type="button" @click.prevent="form.passwordFieldType = !form.passwordFieldType">
                    <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="!form.passwordFieldType">
                      <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                      <g id="eyeOpen" transform="translate(-355 -428)">
                        <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                          <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"/>
                        </g>
                        <rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"/>
                      </g>
                    </svg>
                  </button>
                </div>
              </ValidationProvider>
              <div class="row">
                <div class="col-md-6">
                  <!-- <div class="formType checkRememberBox">
                      <input type="checkbox" id="remember" name="remember">
                      <label for="remember">Remember me</label>
                  </div> -->
                </div>
                <div class="col-md-6">
                  <div class="forgotPasswordBtn">
                    <router-link to="/forgotPassword">Forgot Password?</router-link>
                  </div>
                </div>
              </div>
              <div class="formSubmitBtn" >
                <loaderBtn v-if="isLoading"/>
                <button type="submit" :disabled="googleLoader || microsoftPromptActive" v-else>Login</button>
              </div>
            </form>
            <div class="googleCustomBtn">
              <button disabled v-if="googleLoader">
                <div class="item loading-6">
                  <svg viewBox="25 25 50 50">
                    <circle cx="50" cy="50" r="20"></circle>
                  </svg>
                </div>
              </button>
              <button @click.prevent="googleSignIn" :disabled="googleLoader || microsoftPromptActive || isLoading" v-else>
                <svg enable-background="new 0 0 128 128" id="Social_Icons" version="1.1" viewBox="0 0 128 128" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="_x31__stroke">
                    <g id="Google">
                      <rect clip-rule="evenodd" fill="none" fill-rule="evenodd" height="128" width="128"/>
                      <path clip-rule="evenodd" d="M27.585,64c0-4.157,0.69-8.143,1.923-11.881L7.938,35.648    C3.734,44.183,1.366,53.801,1.366,64c0,10.191,2.366,19.802,6.563,28.332l21.558-16.503C28.266,72.108,27.585,68.137,27.585,64" fill="#FBBC05" fill-rule="evenodd"/>
                      <path clip-rule="evenodd" d="M65.457,26.182c9.031,0,17.188,3.2,23.597,8.436L107.698,16    C96.337,6.109,81.771,0,65.457,0C40.129,0,18.361,14.484,7.938,35.648l21.569,16.471C34.477,37.033,48.644,26.182,65.457,26.182" fill="#EA4335" fill-rule="evenodd"/>
                      <path clip-rule="evenodd" d="M65.457,101.818c-16.812,0-30.979-10.851-35.949-25.937    L7.938,92.349C18.361,113.516,40.129,128,65.457,128c15.632,0,30.557-5.551,41.758-15.951L86.741,96.221    C80.964,99.86,73.689,101.818,65.457,101.818" fill="#34A853" fill-rule="evenodd"/>
                      <path clip-rule="evenodd" d="M126.634,64c0-3.782-0.583-7.855-1.457-11.636H65.457v24.727    h34.376c-1.719,8.431-6.397,14.912-13.092,19.13l20.474,15.828C118.981,101.129,126.634,84.861,126.634,64" fill="#4285F4" fill-rule="evenodd"/>
                    </g>
                  </g>
                </svg>
                Continue With Google
              </button>
            </div>
            <div class="googleCustomBtn" v-if="isDev()">
              <button disabled v-if="microsoftLoader">
                <div class="item loading-6">
                  <svg viewBox="25 25 50 50">
                    <circle cx="50" cy="50" r="20"></circle>
                  </svg>
                </div>
              </button>
              <button @click.prevent="outlookSignInInitiate" :disabled="googleLoader || microsoftPromptActive || isLoading" v-else>
                <svg height="100px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="100px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="_x37_4-outlook">
                    <g>
                      <g>
                        <path d="M479.689,121.431H270.912c-8.23,0-14.91,6.705-14.91,14.958c0,8.251,6.68,14.95,14.91,14.95     h165.322l-91.621,71.447l-65.709-41.901L262.92,206.13l74.559,47.548c2.418,1.56,5.221,2.332,7.996,2.332     c3.221,0,6.473-1.045,9.16-3.14l110.143-85.924v193.736H270.912c-8.23,0-14.91,6.699-14.91,14.946     c0,8.257,6.68,14.959,14.91,14.959h208.777c8.234,0,14.914-6.702,14.914-14.959v-239.24     C494.604,128.136,487.924,121.431,479.689,121.431L479.689,121.431z M479.689,121.431" style="fill:#1B80E4;"/>
                        <path d="M280.428,35.153c-3.404-2.841-7.967-4.069-12.26-3.168L29.566,76.84     c-7.068,1.322-12.169,7.481-12.169,14.687v328.966c0,7.177,5.101,13.364,12.169,14.688l238.602,44.856     c0.893,0.177,1.818,0.271,2.744,0.271c3.459,0,6.828-1.199,9.516-3.445c3.432-2.836,5.393-7.083,5.393-11.512V46.671     C285.82,42.213,283.859,37.995,280.428,35.153L280.428,35.153z M280.428,35.153" style="fill:#2299F8;"/>
                        <path d="M151.609,360.683c-41.1,0-74.563-40.253-74.563-89.72c0-49.462,33.464-89.719,74.563-89.719     c41.1,0,74.565,40.257,74.565,89.719C226.174,320.43,192.709,360.683,151.609,360.683L151.609,360.683z M151.609,211.153     c-24.665,0-44.736,26.824-44.736,59.81c0,32.988,20.071,59.813,44.736,59.813c24.666,0,44.743-26.824,44.743-59.813     C196.352,237.978,176.275,211.153,151.609,211.153L151.609,211.153z M151.609,211.153" style="fill:#FCFCFC;"/>
                      </g>
                    </g>
                  </g>
                  <g id="Layer_1"/>
                </svg>
                Continue With Outlook
              </button>
            </div>
            <div class="formBottomTxt">
              <p>By clicking login you are accepting 
                <a href="https://yeetcommerce.com/yeetcommerce-privacy-notice/" target="_blank">Privacy Policy</a> and 
                <a href="https://yeetcommerce.com/yeetcommerce-terms-of-service/" target="_blank">Terms of conditions</a>
              </p>
              <h4>New to Yeetcommerce? <button @click.prevent="$router.push('onboarding')">Signup</button></h4>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <!-- 2FA authenticator modal -->
    <div class="modalBg passwordResetPopup" :class="authenticatorForm.isOpen ? 'modal-active': ''">
      <div class="modalLayers" @click.prevent="closeAuthenticatorForm"></div>
      <div class="modalBody">
        <div class="productSideBox" style="position:relative; top:15px; right:0; width:100%;">
          <ValidationObserver ref="userAuthenticate">
            <form @submit.prevent="validate('userAuthenticator')">
              <div class="row">
                <div class="col-md-12">
                  <div class="formContentBox">
                    <p>Open your <b>Authenticator</b> app and type the OTP below.</p>
                  </div>
                </div>
                <div class="col-md-12">
                  <ValidationProvider name="OTP" rules="required|numeric|min:6" v-slot="{ errors }">
                    <div class="formContentBox">
                      <OtpInput
                        ref="otpInput"
                        input-classes="otp-input"
                        separator="-"
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        v-model="authenticatorForm.otp"
                        @on-change="handleOtpChange"
                        @on-complete="handleOtpComplete"
                      />
                      <span class="text-danger" >{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-12">
                  <div class="formCustomerBtn">
                    <div class="btnLoaderCtm" v-if="authenticatorForm.isLoading">
                      <div class="item loading-6">
                        <svg viewBox="25 25 50 50">
                          <circle cx="50" cy="50" r="20"></circle>
                        </svg>
                      </div>
                    </div>
                    <button v-else type="submit">Authenticate</button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="closeAuthenticatorForm">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- email verification modal -->
    <div class="modalBg passwordResetPopup" :class="userVerificationForm.isOpen ? 'modal-active': ''">
      <div class="modalLayers" @click.prevent="userVerificationForm.isOpen = false"></div>
      <div class="modalBody">
        <div class="productSideBox" style="position:relative; top:15px; right:0; width:100%;">
          <ValidationObserver ref="userVerificationObserver">
            <form @submit.prevent="validate('userVerification')">
              <div class="row">
                <div class="col-md-12">
                  <div class="formContentBox">
                    <p>Please enter the <b>OTP</b> sent to your provided <b>email address</b>.</p>
                  </div>
                </div>
                <div class="col-md-12">
                  <ValidationProvider name="OTP" rules="required|numeric|min:4" v-slot="{ errors }">
                    <div class="formContentBox">
                      <OtpInput
                        ref="userVerificationOtpInput"
                        input-classes="otp-input"
                        separator="-"
                        :num-inputs="4"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        v-model="userVerificationForm.otp"
                        @on-change="userVerificationOtpChangeHandle"
                        @on-complete="userVerificationOtpCompleteHandle"
                      />
                      <span class="text-danger" >{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-12">
                  <div class="formCustomerBtn">
                    <loaderBtn v-if="userVerificationForm.isLoading"/>
                    <button type="submit" v-else>Authenticate</button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="userVerificationForm.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import VueQr from 'vue-qr'
import OtpInput from "@bachdgvn/vue-otp-input";
import _ from "lodash";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  name:'LoginIn',
  metaInfo() {
    return {
      title: 'Login to Your YeetCommerce.com Account | Start Selling Online',
      meta: [
        {
          name: 'title',
          content: "Yeetcommerce Store Login: Access Your eCommerce Account", 
        },
        {
          name: 'description',
          content: "Yeetcommerce Store Login and manage your eCommerce store with ease. Secure access to all your account features.", 
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: this.fullPath()
        }
      ]
    };
  },
  data:()=>({
    form:{
      email:'',
      password:'',
      passwordFieldType:true,
    },
    authenticatorForm:{
      otp:'',
      isOpen:false,
      isLoading:false
    },
    userVerificationForm:{
      otp:'',
      isOpen:false,
      isLoading:false,
    },
    loginData:{
      user:{}
    },
    isLoading:false,
    path:'',

    //social logins
    //google
    google:{},
    googleLoader:false,
    
    //microsoft
    microsoft:{},
    microsoftPromptActive:false,
    microsoftLoader:false,
    
  }),
  components:{
    VueQr,
    OtpInput,
    loaderBtn
  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings',
    }),
  },
  watch:{
    'settings':{
      handler:function(val, oldVal){

        if(JSON.stringify(val) !== JSON.stringify(oldVal)){

          this.isLoading = false;

          // Avoid triggering unnecessary updates
          this.$nextTick(() => {
            this.$router.push({ path: this.$route.query.redirect ? this.$route.query.redirect : '/' });
          });

        }

      },deep:true
    }
  },
  methods: {
    //login without reCaptcha start (abandoned)
    async submitLogin(){

      this.isLoading = true;
      try{
        let res = await this.$axios.post('/login', this.form);
        if(res.data.status_code == '1002'){

          let user = res.data.user;
          
          user.roles = user.roles.map((role)=>{
            return role.name;
          });
          
          if(user.two_factor_enabled){

            this.loginData.user = _.cloneDeep(user);

            this.authenticatorForm.isOpen = true;

          }else{

            this.$notify({
              type: 'success',
              title: 'Success',
              message: res.data.message,
            });

            this.$cookies.set("meri_web_shop.current_user", user);
            this.$store.dispatch('authLoggedIn', user);
          
            this.$store.commit('bar_graph_module/fetch_bar_graph_request');

            this.$router.push({ path: this.$route.query.redirect ? this.$route.query.redirect : '/' });

          }

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.includes("password") || 
          error.response.data.error.includes("email address") ||
          error.response.data.error.includes("inactive")){

            this.$message({
              type: 'error',
              showClose: true,
              message:  error.response.data.error,
            }); 

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message:  error.response.data.message,
            }); 

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.isLoading = false;

      }
        
    },
    //login without reCaptcha end (abandoned)
    async socialLogin(platform){

      let formData = new FormData();

      if(platform == 'google'){

        formData.append('social_platform','google');
        formData.append('social_id',this.google.id);

        this.googleLoader = true;

      }

      if(platform == 'microsoft'){

        formData.append('social_platform','microsoft');
        formData.append('social_id',this.microsoft.localAccountId);

        this.microsoftLoader = true;

      }
      
      try{
        let res = await this.$axios.post('social/login',formData);
        if(res.data.status_code == "1002"){

          let user = res.data.user;
          
          user.roles = user.roles.map((role)=>{
            return role.name;
          });

          if(user.two_factor_enabled){

            this.loginData.user = _.cloneDeep(user);

            this.authenticatorForm.isOpen = true;

          }else{

            this.$notify({
              type: 'success',
              title: 'Success',
              message: res.data.message,
            });

            this.$cookies.set("meri_web_shop.current_user", user);
            this.$store.dispatch('authLoggedIn', user);
          
            this.$store.commit('bar_graph_module/fetch_bar_graph_request');

            this.$router.push({ path: this.$route.query.redirect ? this.$route.query.redirect : '/' });

          }

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.includes('social account') ||
          error.response.data.error.includes('inactive')){
            
            this.$message({
              type: 'error',
              showClose: true,
              message:  error.response.data.error,
            }); 

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message:  error.response.data.message,
            }); 

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.googleLoader = false;

        this.microsoftLoader = false;

        this.microsoftPromptActive = false;

      }

    },

    //login with reCaptcha
    async loginHandle(){

      this.$notify({
        type: 'info',
        title: 'Authenticating',
        message: "We're authenticating your identity.",
      });

      this.isLoading = true;

      grecaptcha.ready(function() {

        let reCaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;

        grecaptcha.execute(reCaptchaSiteKey, {action: 'submit'}).then( async function(token) {

          let formData = new FormData();

          formData.append('recaptcha_token',token);
          formData.append('email',this.form.email);
          formData.append('password',this.form.password);
          
          this.isLoading = true;
          try{
            let res = await this.$axios.post('web/dashboard/login',formData)
            if(res.data.status_code == '1002'){

              let user = res.data.user;
          
              user.roles = user.roles.map((role)=>{
                return role.name;
              });
              
              if(user.two_factor_enabled){

                this.loginData.user = _.cloneDeep(user);

                this.authenticatorForm.isOpen = true;

              }else{

                this.$notify({
                  type: 'success',
                  title: 'Success',
                  message: res.data.message,
                });

                this.$cookies.set("meri_web_shop.current_user", user);
                
                this.$store.dispatch('authLoggedIn', user);
              
                this.$store.commit('bar_graph_module/fetch_bar_graph_request');

                this.$router.push({ path: this.$route.query.redirect ? this.$route.query.redirect : '/' });

              }

            }else if(res.data.status_code == '1520'){

              this.$notify({
                type: 'warning',
                title: 'Verification Required',
                message: res.data.message,
              });

              this.openUserVerificationForm();

            }
          }catch(error){

            if(error.response){

              if(error.response.data.error.includes("password") || 
              error.response.data.error.includes("email address") ||
              error.response.data.error.includes("support team")){

                this.$message({
                  type: 'error',
                  showClose: true,
                  message:  error.response.data.error,
                }); 

              }else{

                this.$message({
                  type: 'error',
                  showClose: true,
                  message:  error.response.data.message,
                }); 

              }

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          }finally{

            this.isLoading = false;

          }

        }.bind(this));

      }.bind(this));

    },
    //user verification form handling start
    openUserVerificationForm(){

      this.userVerificationForm = {
        otp:'',
        isOpen:true,
        isLoading:false,
      }

      if(this.$refs.userVerificationObserver){

        this.$refs.userVerificationObserver.reset();

      }

      if(this.$refs.userVerificationOtpInput){

        this.$refs.userVerificationOtpInput.clearInput();

      }

    },
    closeUserVerificationForm(){

      this.userVerificationForm = {
        otp:'',
        isOpen:false,
        isLoading:false,
      }

    },
    userVerificationOtpChangeHandle(value){

      this.userVerificationForm.otp = value;

    },
    userVerificationOtpCompleteHandle(value){

      this.userVerificationForm.otp = value;

      this.verifyUser();

    },
    async verifyUser(){

      let formData = new FormData();

      formData.append('email',this.form.email);
      formData.append('otp',this.userVerificationForm.otp);

      this.userVerificationForm.isLoading = true;
      try{
        let res = await this.$axios.post('web/dashboard/user/verification',formData)
        if(res.data.status_code == '1002'){

          this.closeUserVerificationForm();

          let user = res.data.user;
          
          user.roles = user.roles.map((role)=>{
            return role.name;
          });
          
          if(user.two_factor_enabled){

            this.loginData.user = _.cloneDeep(user);

            this.authenticatorForm.isOpen = true;

          }else{

            this.$notify({
              type: 'success',
              title: 'Success',
              message: res.data.message,
            });

            this.$cookies.set("meri_web_shop.current_user", user);
            this.$store.dispatch('authLoggedIn', user);
          
            this.$store.commit('bar_graph_module/fetch_bar_graph_request');

            this.$router.push({ path: this.$route.query.redirect ? this.$route.query.redirect : '/' });

          }

        }
      }catch(error){
        
        this.userVerificationForm.isLoading = false;

        if(error.response){

          if(error.response.data.error.otp){

            this.$message({
              type: 'error',
              showClose: true,
              message:  error.response.data.error.otp[0],
            }); 

          }else if(error.response.data.error.includes("password") || 
          error.response.data.error.includes("email address") ||
          error.response.data.error.includes("inactive") || 
          error.response.data.error.includes("expired")){

            this.$message({
              type: 'error',
              showClose: true,
              message:  error.response.data.error,
            }); 

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message:  error.response.data.message,
            }); 

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }

    },
    //user verification form handling end


    //otp input methods
    handleOtpChange(value){

      this.authenticatorForm.otp = value;

    },
    handleOtpComplete(value){

      this.authenticatorForm.otp = value;

      this.authenticateUser();

    },
    //authenticator methods
    closeAuthenticatorForm(){

      this.authenticatorForm.isOpen = false;

      this.$refs.otpInput.clearInput();

      this.$refs.userAuthenticate.reset();

      this.authenticatorForm = {
        otp: '',
        isOpen: false,
        isLoading:false
      }

      this.loginData.user = {};

    },
    validate(form){

      if(form == 'userAuthenticator'){

        this.$refs.userAuthenticate.validate().then(success => {

          if(!success){

            const errors = Object.entries(this.$refs.userAuthenticate.errors)
            .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);
            this.$refs.userAuthenticate.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            this.authenticateUser();

          }

        });

      }else if(form == 'userVerification'){

        this.$refs.userVerificationObserver.validate().then(success => {

          if(!success){

            const errors = Object.entries(this.$refs.userVerificationObserver.errors)
            .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);
            this.$refs.userVerificationObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            this.verifyUser();

          }

        });

      }

    },
    async authenticateUser(){
      
      let formData = new FormData();

      formData.append("otp",this.authenticatorForm.otp);

      this.authenticatorForm.isLoading = true;
      try{
        let res = await this.$axios.post('/store-admin/authenticator/verify',formData,{
          headers:{
            Authorization: 'Bearer ' + this.loginData.user.token
          }
        });
        if(res.data.status_code == '1002'){

          this.authenticatorForm.isLoading = false;

          this.$cookies.set("meri_web_shop.current_user", this.loginData.user);
          this.$store.dispatch('authLoggedIn', this.loginData.user);
        
          this.$store.commit('bar_graph_module/fetch_bar_graph_request');

          this.closeAuthenticatorForm();

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          this.$router.push({ path: this.$route.query.redirect ? this.$route.query.redirect : '/' });

        }else if(res.data.status_code == '4015'){

          this.authenticatorForm.isLoading = false;

          this.closeAuthenticatorForm();

          this.$message({
            type: 'error',
            showClose: true,
            message: res.data.message,
          });

        }
      }catch(error){

        this.authenticatorForm.isLoading = false;

        if(error.response){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response.data.message,
          });

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.closeAuthenticatorForm();

      }

    },

    //google signIn
    async googleSignIn(){

      let initgoogle = google.accounts.oauth2.initTokenClient({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID_FINAL,
        callback: this.handleCredentialResponse,
        auto_select: false,
        scope: "profile email"
      });

      initgoogle.requestAccessToken();

    },
    async handleCredentialResponse(response){

      await fetch("https://www.googleapis.com/userinfo/v2/me",{
        method: "GET",
         headers: {
          "Authorization": `Bearer ${response.access_token}`
        },
      }).then((userInfo) => {

        return userInfo.json();

      }).then((data)=>{

        this.google = data;

        this.socialLogin('google');

      });

    },

    //outlook signIn
    async outlookSignInInitiate(){ 

      const redurectUrl = window.location.origin + '/login'

      const msalConfig = {
        auth: {
          clientId: process.env.VUE_APP_OUTLOOK_CLIENT_ID,
          authority: 'https://login.microsoftonline.com/common',
          redirectUri: redurectUrl,
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      };

      this.outlookSignInProceed(msalConfig);

    },
    async outlookSignInProceed(msalConfig){
      
      this.microsoftPromptActive = true

      const loginRequest = {
        scopes: ['email','openid', 'profile', 'User.Read'],
        prompt: 'select_account',
      };

      const myMSALObj = new msal.PublicClientApplication(msalConfig);

      myMSALObj.loginPopup(loginRequest).then(response => {

        this.microsoft = response.account;

        this.socialLogin('microsoft');

      }).catch(error => {

        this.microsoftPromptActive = false;

      });

    },

    //meta info methods
    fullPath(){

      return window.location.origin + this.$route.fullPath;

    },

    //environment check
    isDev(){

      if(process.env.VUE_APP_ENV == 'dev'){

        return true;

      }else{

        return false;

      }

    }
  },
  async beforeMount(){

    this.path = window.location.origin + this.$route.path;

    if(this.$route.query.token){

      this.isLoading = true;

      this.$notify({
        type: 'info',
        title: 'Authenticating',
        message: "We're authenticating your identity.",
      });

      let token = this.$route.query.token;

      try{
        let res = await this.$axios.post('/verifyToken',{},{
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        if(res.data.status_code == "1160"){

          let user = res.data.user;

          user.roles = user.roles.map((role)=>{
            return role.name;
          });

          this.$cookies.set("meri_web_shop.current_user", user);
          this.$store.dispatch('authLoggedIn', user);

          this.$store.commit('bar_graph_module/fetch_bar_graph_request');

          this.isLoading = false;
          
          if(this.$route.query.redirect){

            this.$router.push({path: this.$route.query.redirect});

          }else{

            this.$router.push({name: 'product' , params:{isCreateNew: true}});

          }

        }
      }catch(error){

        this.isLoading = false;

        if(error.response){

          this.$message({
            type: 'error',
            showClose: true,
            message: "Something went wrong. Kindly login with your credentials.",
          });

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }

    }

    if(this.$route.query.email && this.$route.query.key){
      
      this.isLoading = true;

      this.$notify({
        type: 'info',
        title: 'Authenticating',
        message: "We're authenticating your identity.",
      });

      let formData = new FormData();
      
      formData.append('email',this.$route.query.email);
      formData.append('key',this.$route.query.key);

      try{
        let res = await this.$axios.post('/store/signin/request/verify',formData);
        if(res.data.status_code == '1371'){
          try{
            let loginRequest = await this.$axios.post('/store/signin',formData)
            if(loginRequest.data.status_code == '1002'){

              this.isLoading = false;

              let user = loginRequest.data.user;
        
              user.roles = user.roles.map((role)=>{
                return role.name
              });

              this.$notify({
                type: 'success',
                title: 'Success',
                message: loginRequest.data.message,
              })

              this.$cookies.set("meri_web_shop.current_user", user);
              this.$store.dispatch('authLoggedIn', user);
            
              this.$store.commit('bar_graph_module/fetch_bar_graph_request');

              this.$router.push({ path: this.$route.query.redirect ? this.$route.query.redirect : '/' });

            }
          }catch(error){

            this.isLoading = false;

            if(error.response){

              if(error.response.data.error.includes('No sign-in request')){

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response.data.error,
                });

              }else{

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response.data.message,
                });

              }

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          }

        }

      }catch(error){

        this.isLoading = false;

        if(error.response){

          if(error.response.data.error.email){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.email[0],
            });

          }else if(error.response.data.error.includes('No sign-in request')){
            
            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else if (error.response.data.error.includes('Unverifed sign-in request')){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }
        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }

    }

    if(this.$route.query.session_id && this.$route.query.store_id){

      this.isLoading = true;

      this.$notify({
        type: 'info',
        title: 'Verifying Payment',
        message: "We're actively working on the verification of your payment.",
      });

      let formData = new FormData();

      formData.append('session_id',this.$route.query.session_id);

      try{
        let res = await this.$axios.post('onboarding/subscription/stripe/verify-payment',formData,{
          headers:{
            'X-Store-Id':this.$route.query.store_id
          }
        });
        if(res.data.status_code == "1174"){

          this.$notify({
            type: "success",
            title: "success",
            message: res.data.message,
          });

          this.$router.replace({'query': null});

          this.isLoading = false;

        }
      }catch(error){
        
        this.$router.replace({'query': null});

        this.isLoading = false;

        if(error.response){

          if(error.response.data.error.includes("Transaction failed")){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error,
            });

          }else if(error.response.data.error.includes("No unverified payment found")){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error,
            });

          }else if(error.response.data.error.includes("No payment request found")){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error,
            });

          }else if(error.response.data.error.includes("The payment request has been")){

            if(error.response.data.error == "The payment request has been verified"){

              this.$notify({
                type: "success",
                title: "Success",
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }


          }else if(error.response.data.error.includes("Request has already")){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }

    }

  },
  mounted(){
  },
}
</script>

<style scoped>
  /* Login New Page Css Start Here */

  .loginPage{
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 24px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #1b3561, #25799d);
    /* background: linear-gradient(45deg, rgba(207, 76, 228, 1) 0%, rgba(128, 32, 225, 1) 100%); */
  }
  .loginWhiteBox{
    max-width: 652px;
    width: 100%;
    background-color: #fff;
    padding: 59px 44px 58px;
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 24%);
  }
  .loginLogoBox{
    width: 160px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .loginLogoBox a{
    display: block;
  }
  .loginLogoBox img{
    width: 100%;
  }
  .loginTitlebox{
    text-align: center;
    margin-bottom: 33px;
  }
  .loginTitlebox h4{
    font-size: 24px;
    color: #000000;
    margin-bottom: 11px;
    font-weight: 700;
  }
  .loginTitlebox p{
    font-size: 14px;
    color: #9E9B9B;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .loginFormBox .formType label{
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
  }
  .loginFormBox .formType label span{
    color: #FD4D5D;
  }
  .loginFormBox .formType input{
    border: 1px solid #e8e8e8 !important;
    background-color: #FCFCFC !important;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    border-radius: 4px;
  }
  .loginFormBox .formType .text-danger{
    font-size: 13px;
    color: #dc3545;
    font-style: italic;
  }
  .text-danger{
    font-size: 13px;
    color: #dc3545;
    font-style: italic;
  }
  /* .formType {
    display: flex;
    flex-direction: column;
  } */
  .formType.checkRememberBox{
    display: flex;
    align-items: center;
  }
  .formType.checkRememberBox input{
    border-radius: 0px !important;
    width: 17px;
    height: 17px;
    margin-right: 10px;
  }
  .formType.checkRememberBox label{
    margin-bottom: 0px !important;
    font-size: 16px;
    font-weight: 400;
  }
  .formType{
    position: relative;
  }
  .formType .PasswordEyeBtn{
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    border: none;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 48px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .forgotPasswordBtn{
    text-align: right;
  }
  .forgotPasswordBtn a{
    font-size: 16px;
    font-weight: 400;
    color: #14223d;
    display: inline-block;
  }
  .loginFormBox .row{
    margin-bottom: 41px;
  }
  .loginFormBox .formSubmitBtn{
    text-align: center;
    margin-bottom: 20px;
  }
  .loginFormBox .formSubmitBtn button{
    width: 60% !important;
    margin: 0 auto;
    font-weight: 700 !important;
    background-color: #14223d;
    /* background: linear-gradient(45deg, rgba(207, 76, 228, 1) 0%, rgba(128, 32, 225, 1) 100%); */
  }
  .formBottomTxt{
    text-align: center;
  }
  .formBottomTxt p{
    font-size: 14px;
    color: #9E9B9B;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .formBottomTxt p a{
    color: #14223d;
    display: inline-block;
    text-decoration: none;
  }
  .formBottomTxt h4{
    color: #9E9B9B;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0px;
  }
  .formBottomTxt h4 button{
    color: #14223d;
    background-color: transparent;
    padding: 0px;
    border-radius: 0px;
    border: none;
  }
  .formSubmitBtn button {
    width: 100%;
    background-color: #14223d !important;
    border: none !important;
    color: #fff;
    padding: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .formSubmitBtn button:hover {
    background-color: #fd4e5d !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .QRContainer{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .formContentBox span{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .googleBtn{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .googleCustomBtn{
    text-align: center;
  }
  .googleCustomBtn button{
    width: 60%;;
    background-color: #ffff !important;
    border: 1px solid #dadce0 !important;
    color: #000;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .googleCustomBtn button:hover{
    background-color: rgba(66,133,244,.04) !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .googleCustomBtn button svg{
    height: 14px;
    width: 14px;
    margin-right: 10px;
  }
  .googleCustomBtn .item.loading-6 svg {
    width: 20px;
    height: 100%;
  }
  .googleCustomBtn .item.loading-6 svg circle{
    fill: none;
    stroke: #000;
    stroke-width: 4;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  /* Login New Page Css End Here */
</style>